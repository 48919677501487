import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import Layout from "../Layout";
import TagManager from "react-gtm-module";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";

import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

import "../styles/templates/career.css";
const ReactMarkdown = require("react-markdown/with-html");
class Career extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: []
    };
  }

  async fetchData() {
    // var locale = this.props.match.params.locale;
    // var filter;
    // if (locale === undefined) {
    //   filter = "title_slug";
    // } else {
    //   filter = `title_${locale}_slug`;
    // }

    const response = await fetch(
      `https://seven.ch/admin/api/collections/get/careers`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "752611b463f91bb7434bacf241650d"
        },
        body: JSON.stringify({
          //filter: { [filter]: this.props.match.params.id },
          filter: { url_handle: this.props.match.params.id },
          lang: this.props.match.params.locale
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    this.setState({
      data: jsonData.entries[0]
    });
    console.log(this.state.data);
  }

  async componentDidMount() {
    window.scroll(0, 0);
    await this.setState({
      fetching: true
    });

    await this.fetchData();

    this.setState({
      fetching: false
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      window.scroll(0, 0);
      await this.setState({
        fetching: true
      });

      await this.fetchData();

      this.setState({
        fetching: false
      });
    }
  }

  render() {
    var splittedDate;
    if (!this.state.fetching) {
      splittedDate = this.state.data.date.split("-");
    }

    if (!this.state.fetching) {
      const tagManagerArgs = {
        dataLayer: {
          page: "article " + this.state.data.title
        },
        dataLayerName: "PageDataLayer"
      };
      TagManager.dataLayer(tagManagerArgs);
    }

    return (
      <div className="career">
        {!this.state.fetching ? (
          <Helmet>
            <html
              lang={
                this.props.match.params.locale
                  ? this.props.match.params.locale
                  : "it"
              }
            />
            <meta charSet="utf-8" />

            <meta property="og:type" content="article" />
            <meta property="og:title" content={this.state.data.title} />

            <title>{this.state.data.title} - Careers - SEVEN GROUP</title>
          </Helmet>
        ) : null}
        <Layout
          url={this.props.match.url}
          locale={this.props.match.params.locale}
        >
          {!this.state.fetching ? (
            <div className="careerInner">
              <div className="careerContent">
                <h1 style={{ textAlign: "center", color: "rgb(220, 33, 33)" }}>
                  {this.state.data.title}
                </h1>
                <div className="date">
                  {splittedDate[2]}-{splittedDate[1]}-{splittedDate[0]}
                </div>
                <ReactMarkdown
                  source={this.state.data.content}
                  escapeHtml={false}
                />
                <br />
                <br />
                <br />
                <div style={{ textAlign: "center" }}>
                  <Button
                    style={{
                      color: "#fff",
                      backgroundColor: "rgb(220, 33, 33)",
                      padding: "1rem 2rem"
                    }}
                    variant="contained"
                    component={Link}
                    to={
                      !this.props.match.params.locale
                        ? `/careers`
                        : `/${this.props.match.params.locale}/careers`
                    }
                  >
                    {this.props.match.params.locale === "it" ||
                    !this.props.match.params.locale
                      ? "Candidati"
                      : this.props.match.params.locale === "en"
                      ? "Apply"
                      : this.props.match.params.locale === "de"
                      ? "Kandidatur"
                      : "Apply"}
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </Layout>
      </div>
    );
  }
}

export default Career;
