import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import "../styles/cockpitComponents/actions.css";
import Button from "@material-ui/core/Button";

class Actions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    //console.log(this.props.data);

    return (
      <div
        className="actions"
        style={{
          backgroundColor: this.props.bgColor
        }}
      >
        {this.props.data.settings.actions.map((action, i) => {
          if (action.value.url.indexOf("/") !== 0) {
            return (
              <Button
                key={i}
                variant="contained"
                target="_blank"
                title={action.value.label}
                style={{
                  color: this.props.bgColor,
                  backgroundColor: this.props.textColor
                }}
                href={action.value.url}
              >
                {action.value.label}
              </Button>
            );
          } else {
            return (
              <Button
                key={i}
                component={Link}
                variant="contained"
                title={action.value.label}
                style={{
                  color: this.props.bgColor,
                  backgroundColor: this.props.textColor
                }}
                to={
                  !this.props.locale
                    ? action.value.url
                    : `/${this.props.locale}${action.value.url}`
                }
              >
                {action.value.label}
              </Button>
            );
          }
        })}
      </div>
    );
  }
}

export default Actions;
