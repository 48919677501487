import React, { PureComponent } from "react";
import Fade from "react-reveal/Fade";
import "../styles/cockpitComponents/info.css";
const ReactMarkdown = require("react-markdown/with-html");
class Info extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    //console.log(this.props.data);
    return (
      <div
        className="infosContainer"
        style={{
          backgroundColor: this.props.bgColor
        }}
      >
        <Fade bottom cascade>
          <div className="infos">
            {this.props.data.settings.info.map((info, i) => {
              //console.log(offer);

              return (
                <div className="info" key={i}>
                  <h2
                    style={{
                      color: this.props.titleColor
                    }}
                  >
                    {info.value.title}
                  </h2>
                  <p
                    style={{
                      color: this.props.textColor
                    }}
                  >
                    <ReactMarkdown
                      source={info.value.content}
                      escapeHtml={false}
                    />
                  </p>
                </div>
              );
            })}
          </div>
        </Fade>
      </div>
    );
  }
}

export default Info;
