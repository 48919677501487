import React, { PureComponent } from "react";

class Loading extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className="loading" />;
  }
}

export default Loading;
