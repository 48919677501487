import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LanguageIcon from "@material-ui/icons/Language";

import "../BeSafe.css";

class BeSafeClubAscona extends Component {
  constructor(props) {
    super(props);
    this.state = {
      safeGroupLength: null,
      loading: false,
      success: false,
      lang: "en"
    };
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.mailObj = {};

    let dateObj = new Date();
    let year = dateObj.getFullYear();
    let month = dateObj.getMonth() + 1;
    let day = dateObj.getDate();
    let hour = dateObj.getHours();
    let minute = dateObj.getMinutes();

    this.mailObj["Date"] = day + "/" + month + "/" + year;
    this.mailObj["Time"] = hour + ":" + minute;

    // const tablecodeField = document.querySelectorAll(".tablecodeField");
    //
    // for (var i = 0; i < tablecodeField.length; i++) {
    //   this.mailObj["Table"] = tablecodeField[i].querySelectorAll(
    //     "input"
    //   )[0].value;
    // }

    const safeGroupsObj = document.querySelectorAll(".safeGroup");

    for (var i = 0; i < safeGroupsObj.length; i++) {
      const safeGroupFields = safeGroupsObj[i].querySelectorAll(
        ".safeGroupField"
      );

      for (var j = 0; j < safeGroupFields.length; j++) {
        const safeGroupFieldsInputs = safeGroupFields[j].querySelectorAll(
          "input"
        );

        for (var y = 0; y < safeGroupFieldsInputs.length; y++) {
          this.mailObj[
            safeGroupFieldsInputs[y].getAttribute("name") //+
            // " (" +
            // parseInt(i + 1) +
            // ")"
          ] = safeGroupFieldsInputs[y].value;
        }
      }
    }

    console.log(this.mailObj);

    this.setState({
      loading: true
    });

    let sendMail = await fetch(
      "https://seven.ch/admin/api/forms/submit/besafeclubascona",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "752611b463f91bb7434bacf241650d"
        },
        body: JSON.stringify({
          form: this.mailObj
        })
      }
    );

    if (!sendMail.ok) {
      // this.setState({
      //   loading: false,
      //   submitted: true,
      //   error: true
      console.log("error");

      return;
    }
    console.log("success");
    document.getElementById("besafeContainer").style.backgroundColor =
      "#bada55";
    this.setState({
      loading: false,
      success: true
    });
    // this.setState({
    //   loading: false,
    //   submitted: true,
    //   success: true
    // });
    //
    // this.mailObj = {};

    // window.scroll(0, 0);
  }

  StyledTextField = styled(TextField)`
    label {
      color: #ffffff;
      opacity: 1;
    }
    label.focused {
      color: #ffffff;
      opacity: 1;
    }
    .MuiInputBase-input {
      color: #ffffff;
    }
    .MuiOutlinedInput-root {
      fieldset {
        border-color: #ffffff;
        opacity: 0.6;
        color: #ffffff;
      }
      &:hover fieldset {
        border-color: #ffffff;
        opacity: 1;
      }
      &.Mui-focused fieldset {
        color: #ffffff;
        opacity: 1;
      }
    }
  `;

  tablecodeField = lang => {
    return (
      <this.StyledTextField
        style={{ width: "100%", marginBottom: "16px" }}
        required={true}
        id="Table"
        inputProps={{ pattern: "[A-Za-z]{2}[0-9]{2,3}" }}
        name="Table"
        label={
          lang === "en"
            ? "Table"
            : lang === "it"
            ? "Tavolo"
            : lang === "de"
            ? "Tabelle"
            : null
        }
        margin="normal"
        variant="outlined"
        className="tablecodeField"
      />
    );
  };

  safeGroup = (index, lang) => {
    return (
      <div
        className="safeGroup"
        style={{
          borderTop: "solid 3px",
          paddingBottom: "8px",
          position: "relative"
        }}
      >
        <span
          className="removesafeGroup"
          onClick={e => {
            e.target.closest(".safeGroup").remove();
            this.setState({
              safeGroupLength: this.safeGroups.length
            });
          }}
        >
          <CloseIcon />
        </span>
        <this.StyledTextField
          style={{ width: "100%" }}
          required={true}
          id={`Name${index}`}
          name={`Name`}
          label={
            lang === "en"
              ? "Name"
              : lang === "it"
              ? "Nome"
              : lang === "de"
              ? "Name"
              : null
          }
          margin="normal"
          variant="outlined"
          className="safeGroupField"
        />
        <this.StyledTextField
          style={{ width: "100%" }}
          required={true}
          id={`Lastname${index}`}
          name={`Lastname`}
          label={
            lang === "en"
              ? "Last Name"
              : lang === "it"
              ? "Cognome"
              : lang === "de"
              ? "Nachname"
              : null
          }
          margin="normal"
          variant="outlined"
          className="safeGroupField"
        />
        <this.StyledTextField
          style={{ width: "100%" }}
          required={true}
          id={`Phone${index}`}
          name={`Phone`}
          label={
            lang === "en"
              ? "Phone number"
              : lang === "it"
              ? "Telefono"
              : lang === "de"
              ? "Telefonnummer"
              : null
          }
          margin="normal"
          variant="outlined"
          className="safeGroupField"
        />
        <this.StyledTextField
          style={{ width: "100%" }}
          required={true}
          id={`Address${index}`}
          name={`Address`}
          label={
            lang === "en"
              ? "Address"
              : lang === "it"
              ? "Indirizzo"
              : lang === "de"
              ? "Adresse"
              : null
          }
          margin="normal"
          variant="outlined"
          className="safeGroupField"
        />
        <this.StyledTextField
          style={{ width: "50%" }}
          required={true}
          id={`City${index}`}
          name={`City`}
          label={
            lang === "en"
              ? "City"
              : lang === "it"
              ? "Città"
              : lang === "de"
              ? "Ort"
              : null
          }
          margin="normal"
          variant="outlined"
          className="safeGroupField"
        />
        <this.StyledTextField
          style={{ width: "50%" }}
          required={true}
          id={`CAP${index}`}
          name={`CAP`}
          label={
            lang === "en"
              ? "CAP"
              : lang === "it"
              ? "CAP"
              : lang === "de"
              ? "CAP"
              : null
          }
          margin="normal"
          variant="outlined"
          className="safeGroupField"
        />
      </div>
    );
  };

  componentDidMount() {
    this.safeGroups = [];

    if (
      (window.location.search &&
        window.location.search.indexOf("lang=it") !== -1) ||
      !window.location.search
    ) {
      this.setState({
        lang: "it"
      });
      this.safeGroups.push(this.safeGroup(this.safeGroups.length, "it"));
    }
    if (
      window.location.search &&
      window.location.search.indexOf("lang=en") !== -1
    ) {
      this.setState({
        lang: "en"
      });
      this.safeGroups.push(this.safeGroup(this.safeGroups.length, "en"));
    }
    if (
      window.location.search &&
      window.location.search.indexOf("lang=de") !== -1
    ) {
      this.setState({
        lang: "de"
      });
      this.safeGroups.push(this.safeGroup(this.safeGroups.length, "de"));
    }

    this.setState({
      safeGroupLength: this.safeGroups.length
    });
  }

  render() {
    return (
      <div id="besafeContainer">
        {this.state.loading ? <div className="besafeLoader" /> : null}

        {this.state.success ? (
          <div className="besafeInner success">
            <span className="svg">
              <CheckCircleIcon />
            </span>
            <br />
            <br />
            <p style={{ textAlign: "center" }}>
              <strong style={{ fontSize: "2.6rem" }}>
                {this.state.lang === "en"
                  ? "Registration complete!"
                  : this.state.lang === "it"
                  ? "Registrazione completata!"
                  : this.state.lang === "de"
                  ? "Registrierung vervollständigt!"
                  : null}
              </strong>
              <br />
              <strong style={{ fontSize: "1.6rem" }}>
                {this.state.lang === "en"
                  ? "Show this screen at the entrance to access the Club."
                  : this.state.lang === "it"
                  ? "Mostra questa schermata all’ingresso per accedere al Club."
                  : this.state.lang === "de"
                  ? "Zeigen Sie diesen Bildschirm am Eingang, um Zugang zum Club zu erhalten"
                  : null}
              </strong>
            </p>
            <br />
            <ul>
              {Object.entries(this.mailObj).map(function([key, value], index) {
                return (
                  <li>
                    {key}: <strong>{value}</strong>
                  </li>
                );
              })}
            </ul>

            <Button
              href="https://mailchi.mp/seven/newsletter"
              target="_blank"
              style={{
                width: "100%",
                height: "4rem",
                color: "#333333",
                backgroundColor: "#ffffff"
              }}
              variant="contained"
            >
              {this.state.lang === "en"
                ? "Sign-up to our Newsletter"
                : this.state.lang === "it"
                ? "Iscriviti alla Newsletter"
                : this.state.lang === "de"
                ? "Abonnieren Sie den Newsletter"
                : null}
            </Button>
          </div>
        ) : (
          <>
            <div className="besafeInner">
              <div className="langSelector">
                <LanguageIcon />
                <a href="/besafeclubascona?lang=it">it</a>
                <a href="/besafeclubascona?lang=de">de</a>
                <a href="/besafeclubascona?lang=en">en</a>
              </div>
              <h1>Be Safe</h1>
              <br />
              <p>
                {this.state.lang === "en"
                  ? "The data will be treated confidentially e completely canceled after 14 days. The cantonal medical office can request contact details if it deems it necessary."
                  : this.state.lang === "it"
                  ? "I dati verranno trattati confidenzialmente e completamente cancellati dopo 14 giorni. L’ufficio medico cantonale può richiedere i dati di contatto qualora lo ritenga necessario."
                  : this.state.lang === "de"
                  ? "Die Kontaktdaten werden vertraulich behandelt und nach 14 Tagen komplett gelöscht. Das Bundesamt für Gesundheit kann diese anfordern sollte es dies für nötig halten."
                  : null}
              </p>
              <form
                onSubmit={e => {
                  this.handleSubmit(e);
                }}
              >
                <div id="safeGroups">{this.safeGroups}</div>

                <br />

                <br />
                <Button
                  type="submit"
                  style={{
                    width: "100%",
                    height: "4rem",
                    color: "#333333",
                    backgroundColor: "#ffffff"
                  }}
                  variant="contained"
                >
                  {this.state.lang === "en"
                    ? "Send"
                    : this.state.lang === "it"
                    ? "Invia"
                    : this.state.lang === "de"
                    ? "Send"
                    : null}
                </Button>
              </form>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default BeSafeClubAscona;
