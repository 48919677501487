import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import Layout from "../Layout";
import ArticlesFeed from "../cockpitComponents/ArticlesFeed";
import TagManager from "react-gtm-module";

import { Link } from "react-router-dom";

import Fade from "react-reveal/Fade";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import "../styles/templates/articles.css";
var d = new Date();
var articleDate;

class Articles extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: [],
      limit: 10,
      skip: 0,
      noMore: false
    };
  }

  async fetchData() {
    const response = await fetch(
      `https://seven.ch/admin/api/collections/get/blog`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "752611b463f91bb7434bacf241650d"
        },
        body: JSON.stringify({
          filter: {
            published: true,
            $or: [
              { title: { $regex: this.state.searchterm } },
              { content: { $regex: this.state.searchterm } }
            ]
          },
          lang: this.props.match.params.locale,
          sort: { date: -1 },
          skip: 0,
          limit: this.state.limit
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    this.setState({
      data: jsonData.entries,
      noMore:
        jsonData.total <= this.state.skip + this.state.limit ? true : false
    });
  }

  async fetchMore() {
    const response = await fetch(
      `https://seven.ch/admin/api/collections/get/blog`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "752611b463f91bb7434bacf241650d"
        },
        body: JSON.stringify({
          filter: {
            published: true,

            $or: [
              { title: { $regex: this.state.searchterm } },
              { content: { $regex: this.state.searchterm } }
            ]
          },
          lang: this.props.match.params.locale,
          sort: { date: -1 },
          skip: this.state.limit + this.state.skip,
          limit: this.state.limit
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();
    var newdata = this.state.data.concat(jsonData.entries);
    this.setState({
      data: newdata,
      skip: this.state.skip + this.state.limit,
      noMore:
        jsonData.total <= this.state.skip + this.state.limit ? true : false
    });
    console.log(jsonData);
  }

  async componentDidMount() {
    window.scroll(0, 0);
    await this.setState({
      fetching: true
    });

    await this.fetchData();

    this.setState({
      fetching: false
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      window.scroll(0, 0);
      await this.setState({
        fetching: true
      });
      await this.fetchData();

      this.setState({
        fetching: false
      });
    }
  }

  render() {
    if (!this.state.fetching) {
      const tagManagerArgs = {
        dataLayer: {
          page: "news"
        },
        dataLayerName: "PageDataLayer"
      };
      TagManager.dataLayer(tagManagerArgs);
    }
    return (
      <div className="articles">
        {!this.state.fetching ? (
          <Helmet>
            <html
              lang={
                this.props.match.params.locale
                  ? this.props.match.params.locale
                  : "it"
              }
            />
            <meta charSet="utf-8" />
            <title>news - seven group</title>
          </Helmet>
        ) : null}
        <Layout
          url={this.props.match.url}
          locale={this.props.match.params.locale}
        >
          <div className="articlesContainer">
            <div className="articlesFeed">
              <div
                className="articlesFeedContainer"
                style={{
                  backgroundColor: this.props.bgColor,
                  position: "relative"
                }}
              >
                <Fade bottom cascade>
                  <div className="articlesFeedContainerInner">
                    {!this.state.fetching
                      ? this.state.data.map((article, i) => {
                          const splittedDate = article.date.split("-");

                          return (
                            <Card
                              key={i}
                              className={
                                i === 0
                                  ? "articleFeedCard first"
                                  : "articleFeedCard"
                              }
                            >
                              <Link
                                className="bigLink"
                                title={article.title}
                                to={
                                  !this.props.match.params.locale
                                    ? `/news/${article.url_handle}`
                                    : `/${
                                        this.props.match.params.locale
                                      }/news/${article.url_handle}`
                                }
                              >
                                <CardActionArea>
                                  {article.image ? (
                                    <CardMedia
                                      image={`https://seven.ch/admin/api/cockpit/image?token=752611b463f91bb7434bacf241650d&src=${
                                        article.image._id
                                      }&w=400&h=260&q=80&o=true`}
                                      title={article.image.title}
                                      style={{ height: "260px" }}
                                    />
                                  ) : null}
                                  <CardContent>
                                    <p
                                      style={{
                                        margin: 0,
                                        padding: "0 0 0.5rem 0",
                                        textTransform: "uppercase"
                                      }}
                                    >
                                      <strong>{article.title}</strong>
                                    </p>
                                    <p
                                      style={{
                                        margin: 0,
                                        padding: "0 0 0.5rem 0"
                                      }}
                                    >
                                      <i>
                                        {" "}
                                        {splittedDate[2]}-{splittedDate[1]}-
                                        {splittedDate[0]}
                                      </i>
                                    </p>
                                    <p style={{ opacity: 0.8 }}>
                                      {article.excerpt}
                                    </p>
                                  </CardContent>
                                </CardActionArea>
                              </Link>
                              <CardActions
                                className="articleFeedCardActions"
                                style={{ display: "none" }}
                              >
                                <Button
                                  size="small"
                                  component={Link}
                                  color="primary"
                                  variant="contained"
                                  to={
                                    !this.props.match.params.locale
                                      ? `/news/${article.url_handle}`
                                      : `/${
                                          this.props.match.params.locale
                                        }/news/${article.url_handle}`
                                  }
                                >
                                  Learn More
                                </Button>
                              </CardActions>
                            </Card>
                          );
                        })
                      : null}
                  </div>
                </Fade>
                {!this.state.noMore ? (
                  <Button
                    onClick={() => {
                      this.fetchMore();
                    }}
                    variant="contained"
                    color="primary"
                  >
                    More
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default Articles;
