import React, { PureComponent } from "react";
import "../styles/cockpitComponents/social.css";
import { SocialIcon } from "react-social-icons";
import Fade from "react-reveal/Fade";
class Actions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="socials"
        style={{
          backgroundColor: this.props.bgColor
        }}
      >
        {this.props.data.settings.social_links.map((social, i) => {
          return (
            <Fade bottom key={i}>
              <SocialIcon bgColor={this.props.textColor} url={social.value} />
            </Fade>
          );
        })}
      </div>
    );
  }
}

export default Actions;
