import React, { PureComponent } from "react";
import Fade from "react-reveal/Fade";
import "../styles/cockpitComponents/content.css";
const ReactMarkdown = require("react-markdown/with-html");
class Content extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    //console.log(this.props.data);
    var width = "1024px";

    var alignment = "left";

    if (this.props.data.settings.alignment === "left") {
      alignment = "left";
    }

    if (this.props.data.settings.alignment === "right") {
      alignment = "right";
    }

    if (this.props.data.settings.alignment === "center") {
      alignment = "center";
    }

    if (this.props.data.settings.alignment === "justify") {
      alignment = "justify";
    }

    if (this.props.data.settings.width === "large") {
      width = "1280px";
    }
    if (this.props.data.settings.width === "medium") {
      width = "1024px";
    }
    if (this.props.data.settings.width === "small") {
      width = "640px";
    }
    return (
      <div
        className="contentContainer"
        style={{
          backgroundColor: this.props.bgColor
        }}
      >
        <Fade bottom>
          <div
            className="contentInner"
            style={{
              maxWidth: width
            }}
          >
            <span
              className="contentPara"
              style={{
                color: this.props.textColor,
                textAlign: alignment
              }}
            >
              <ReactMarkdown
                source={this.props.data.settings.content}
                escapeHtml={false}
              />
            </span>
          </div>
        </Fade>
      </div>
    );
  }
}

export default Content;
