import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import Layout from "../Layout";
import TagManager from "react-gtm-module";

import "../styles/templates/article.css";
const ReactMarkdown = require("react-markdown/with-html");
class Article extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: []
    };
  }

  async componentDidMount() {
    var that = this;

    window.addEventListener(
      "message",
      function(event) {
        console.log("message received:  ", event);
        console.log("entry: ", event.data.entry);
        that.setState({
          data: event.data.entry,
          fetching: false
        });
      },
      false
    );
  }

  render() {
    var splittedDate;
    if (!this.state.fetching && this.state.data.date) {
      splittedDate = this.state.data.date.split("-");
    }

    return (
      <div className="article">
        {!this.state.fetching ? (
          <Helmet>
            <html
              lang={
                this.props.match.params.locale
                  ? this.props.match.params.locale
                  : "it"
              }
            />
            <meta charSet="utf-8" />
            <title>{this.state.data.title} - news - SEVEN GROUP</title>
          </Helmet>
        ) : null}
        <Layout
          url={this.props.match.url}
          locale={this.props.match.params.locale}
        >
          {!this.state.fetching ? (
            <div className="articleInner">
              <img
                className="articleImage"
                alt={this.state.data.title}
                src={`https://seven.ch/admin/storage/uploads${
                  this.state.data.image.path
                }`}
              />
              <div className="articleContent">
                <h1 style={{ textAlign: "center" }}>{this.state.data.title}</h1>
                <div className="date">
                  {splittedDate[2]}-{splittedDate[1]}-{splittedDate[0]}
                </div>
                <ReactMarkdown
                  source={this.state.data.content}
                  escapeHtml={false}
                />
              </div>
            </div>
          ) : null}
        </Layout>
      </div>
    );
  }
}

export default Article;
