import React, { PureComponent } from "react";

import { Link } from "react-router-dom";

import Fade from "react-reveal/Fade";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import "../styles/cockpitComponents/articlesFeed.css";

class ArticlesFeed extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: []
    };
  }

  async fetchData() {
    const response = await fetch(
      `https://seven.ch/admin/api/collections/get/blog`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "e9a764154266ec9a59b91348aa1966"
        },
        body: JSON.stringify({
          filter: { published: true },
          lang: this.props.locale,
          sort: { date: -1 },
          limit: this.props.limit ? this.props.limit : 4
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    this.setState({
      data: jsonData
    });
  }

  async componentDidMount() {
    window.scroll(0, 0);
    await this.setState({
      fetching: true
    });

    await this.fetchData();
    this.setState({
      fetching: false
    });
  }

  render() {
    return (
      <div className="articlesFeed">
        <div
          className="articlesFeedContainer"
          style={{
            backgroundColor: this.props.bgColor
          }}
        >
          <Fade bottom cascade>
            <div className="articlesFeedContainerInner">
              {!this.state.fetching
                ? this.state.data.entries.map((article, i) => {
                    const splittedDate = article.date.split("-");

                    return (
                      <Card key={i} className="articleFeedCard">
                        <Link
                          className="bigLink"
                          title={article.title}
                          to={
                            !this.props.locale
                              ? `/news/${article.url_handle}`
                              : `/${this.props.locale}/news/${
                                  article.url_handle
                                }`
                          }
                        >
                          <CardActionArea>
                            <CardMedia
                              image={`https://seven.ch/admin/api/cockpit/image?token=752611b463f91bb7434bacf241650d&src=${
                                article.image._id
                              }&w=400&h=260&q=50&o=true`}
                              title={article.image.title}
                              style={{ height: "260px" }}
                            />

                            <CardContent>
                              <p
                                style={{
                                  margin: 0,
                                  padding: "0 0 0.5rem 0",
                                  textTransform: "uppercase"
                                }}
                              >
                                <strong>{article.title}</strong>
                              </p>
                              <p
                                style={{
                                  margin: 0,
                                  padding: "0 0 0.5rem 0"
                                }}
                              >
                                <i>
                                  {" "}
                                  {splittedDate[2]}-{splittedDate[1]}-
                                  {splittedDate[0]}
                                </i>
                              </p>
                              <p style={{ opacity: 0.8 }}>{article.excerpt}</p>
                            </CardContent>
                          </CardActionArea>
                        </Link>
                        <CardActions
                          className="articleFeedCardActions"
                          style={{ display: "none" }}
                        >
                          <Button
                            size="small"
                            component={Link}
                            color="primary"
                            variant="contained"
                            to={
                              !this.props.locale
                                ? `/news/${article.url_handle}`
                                : `/${this.props.locale}/news/${
                                    article.url_handle
                                  }`
                            }
                          >
                            Learn More
                          </Button>
                        </CardActions>
                      </Card>
                    );
                  })
                : null}
              {this.props.data.settings && this.props.data.settings.cta ? (
                <div style={{ paddingTop: "2rem" }}>
                  <Button
                    style={{
                      backgroundColor: this.props.textColor
                    }}
                    variant="contained"
                    component={Link}
                    to={
                      !this.props.locale
                        ? `/news`
                        : `/${this.props.locale}/news`
                    }
                  >
                    {this.props.data.settings.cta}
                  </Button>
                </div>
              ) : null}
            </div>
          </Fade>
        </div>
      </div>
    );
  }
}

export default ArticlesFeed;
