import React from "react";
import Button from "@material-ui/core/Button";

import ListItem from "@material-ui/core/ListItem";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { Link } from "react-router-dom";
import "../styles/components/simpleMenuList.css";

// ADD THIS IN BUTTON TO ARROW DOWN UP
// {this.state[this.props.menuLabel] && this.state.open ? (
//   <ExpandLess />
// ) : (
//   <ExpandMore />
// )}

class SimpleMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  handleClose = e => {
    this.setState({ open: false });
  };
  handleClick = e => {
    if (this.state.open) {
      this.setState({ open: false });
    } else {
      this.setState({ open: true });
    }
  };

  render() {
    return (
      <ClickAwayListener onClickAway={this.handleClose}>
        <div className="simplemenulist">
          <Button onClick={this.handleClick} color="inherit">
            <span dangerouslySetInnerHTML={{ __html: this.props.menuLabel }} />
          </Button>
          {this.state.open ? (
            <div
              className="simpleMenuListContent"
              style={{
                backgroundColor: this.props.bgColor
              }}
            >
              {this.props.menuItems.map((item1, i1) => {
                return item1.field.name === "page" ? (
                  <ListItem
                    key={i1}
                    component={Link}
                    onClick={this.handleClose.bind(this, this.props.menuLabel)}
                    to={
                      !this.props.locale
                        ? `/${this.props.menuHandle}/${
                            item1.value.link.url_handle
                          }`
                        : `/${this.props.locale}/${this.props.menuHandle}/${
                            item1.value.link.url_handle
                          }`
                    }
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: item1.value.label }}
                    />
                  </ListItem>
                ) : item1.field.name === "custom" ? (
                  item1.value.url.indexOf("/") === 0 ? (
                    <ListItem
                      key={i1}
                      component={Link}
                      to={
                        !this.props.locale
                          ? `${item1.value.url}`
                          : `/${this.props.locale}${item1.value.url}`
                      }
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: item1.value.label }}
                      />
                    </ListItem>
                  ) : (
                    <ListItem
                      key={i1}
                      component="a"
                      target={item1.value.new_tab ? "_blank" : ""}
                      rel={item1.value.new_tab ? "noopener noreferrer" : ""}
                      href={item1.value.url}
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: item1.value.label }}
                      />
                    </ListItem>
                  )
                ) : null;
              })}
            </div>
          ) : null}
        </div>
      </ClickAwayListener>
    );
  }
}

export default SimpleMenu;
