import React, { PureComponent } from "react";
import Fade from "react-reveal/Fade";
import "../styles/cockpitComponents/imageAndText.css";
const ReactMarkdown = require("react-markdown/with-html");
class Content extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div
          className="imageAndTextContainer"
          style={{
            backgroundColor: this.props.bgColor
          }}
        >
          <Fade bottom cascade>
            <div className="imageAndText">
              <div className="imageAndTextElem thegrid padding">
                <Fade left>
                  <div className="thecell x50 xs-x100 padding">
                    <img
                      src={`https://seven.ch/admin/api/cockpit/image?token=752611b463f91bb7434bacf241650d&src=${
                        this.props.data.settings.image._id
                      }&w=640&h=640&q=50&o=true`}
                      alt={this.props.data.settings.image.title}
                    />
                  </div>
                </Fade>
                <div
                  className="thecell x50 xs-x100 padding"
                  style={{
                    color: this.props.textColor
                  }}
                >
                  <ReactMarkdown
                    source={this.props.data.settings.text}
                    escapeHtml={false}
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    );
  }
}

export default Content;
