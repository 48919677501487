import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import "../styles/cockpitComponents/imageLinks.css";
const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

class ImageLinks extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    //console.log(this.props.data);
    return (
      <div className="imagelinks">
        {this.props.data.settings.imagelinks.map((imagelink, i) => {
          return imagelink.value.image ? (
            <ConditionalWrapper
              condition={imagelink.field.name === "fileset"}
              wrapper={children => (
                <a
                  key={i}
                  className="imagelink"
                  href={`https://seven.ch/${imagelink.value.file}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    backgroundImage:
                      "url(https://seven.ch/admin/api/cockpit/image?token=752611b463f91bb7434bacf241650d&src=" +
                      imagelink.value.image._id +
                      "&w=900&h=900&q=50&o=true)"
                  }}
                >
                  {children}
                </a>
              )}
            >
              <ConditionalWrapper
                condition={
                  imagelink.field.name === "linkset" &&
                  imagelink.value.url.indexOf("/") !== 0
                }
                wrapper={children => (
                  <a
                    key={i}
                    className="imagelink"
                    href={imagelink.value.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={imagelink.value.label}
                    style={{
                      backgroundImage:
                        "url(https://seven.ch/admin/api/cockpit/image?token=752611b463f91bb7434bacf241650d&src=" +
                        imagelink.value.image._id +
                        "&w=900&h=900&q=50&o=true)"
                    }}
                  >
                    {children}
                  </a>
                )}
              >
                <ConditionalWrapper
                  condition={
                    imagelink.field.name === "linkset" &&
                    imagelink.value.url.indexOf("/") === 0
                  }
                  wrapper={children => (
                    <Link
                      key={i}
                      className="imagelink"
                      to={
                        !this.props.locale
                          ? imagelink.value.url
                          : `/${this.props.locale}${imagelink.value.url}`
                      }
                      title={imagelink.value.label}
                      style={{
                        backgroundImage:
                          "url(https://seven.ch/admin/api/cockpit/image?token=752611b463f91bb7434bacf241650d&src=" +
                          imagelink.value.image._id +
                          "&w=900&h=900&q=50&o=true)"
                      }}
                    >
                      {children}
                    </Link>
                  )}
                >
                  <span>
                    <Fade bottom>{imagelink.value.label}</Fade>
                  </span>
                </ConditionalWrapper>
              </ConditionalWrapper>
            </ConditionalWrapper>
          ) : null;
        })}
      </div>
    );
  }
}

export default ImageLinks;
