import React, { PureComponent } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import "../styles/cockpitComponents/form.css";
const ReactMarkdown = require("react-markdown/with-html");

var mailObj = {};

class Form extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      success: false,
      error: false,
      withLabels: false,
    };
  }

  handleField = (name) => (event) => {
    event.preventDefault();
    mailObj[name] = event.target.value;
  };

  handleChange = (name) => (event) => {
    mailObj[name] = event.target.checked;
  };

  handleFile = (name) => (event) => {
    var file = event.target;
    event.preventDefault();
    // console.log(event.target.files[0]);
    console.log(event.target);
    if (
      event.target.files[0] &&
      //event.target.files[0].type === "image/jpeg" &&
      event.target.files[0].size <= 2097152
    ) {
      event.target.disabled = true;

      var formData = new FormData();

      formData.append("files[]", event.target.files[0]);
      formData.append("meta[folder]", "5e5f980a34306570ef000209");

      var xhr = new XMLHttpRequest();
      xhr.open("POST", "https://seven.ch/admin/api/cockpit/addAssets", true);
      xhr.setRequestHeader("Process-Data", false);
      xhr.setRequestHeader("Cockpit-Token", "752611b463f91bb7434bacf241650d");
      xhr.onreadystatechange = function () {
        // Call a function when the state changes.
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          // Request finished. Do processing here.
          console.log(JSON.parse(xhr.responseText));
          mailObj[name] = `https://www.seven.ch/admin/storage/uploads${
            JSON.parse(xhr.responseText).assets[0].path
          }`;
        } else {
          console.log("Error", this.status);
        }
      };
      xhr.send(formData);
      file.parentNode.style.backgroundColor = "rgba(100, 255, 0, 0.2)";
      //file.insertAdjacentHTML("afterend", "<div style>success</div>");
    } else {
      // if (
      //   event.target.files[0] &&
      //   event.target.files[0].type !== "image/jpeg"
      // ) {
      //   event.target.type = "";
      //   event.target.type = "file";
      //   alert("Image must be JPEG format");
      // }
      if (event.target.files[0] && event.target.files[0].size > 2097152) {
        event.target.type = "";
        event.target.type = "file";
        alert("Maximum file size is 2mb");
      }
    }
  };

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, "g"), replace);
  }

  async handleSubmit(e) {
    e.preventDefault();

    // this.setState({
    //   loading: true,
    // });

    this.setState({
      loading: false,
      submitted: true,
      success: true,
    });

    fetch(
      "https://seven.ch/admin/api/forms/submit/" +
        this.props.data.settings.form_name,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "752611b463f91bb7434bacf241650d",
        },
        body: JSON.stringify({
          form: mailObj,
        }),
      }
    ).then((sendMail) => {
      if (!sendMail.ok) {
        this.setState({
          loading: false,
          submitted: true,
          error: true,
        });
        return;
      }

      this.setState({
        loading: false,
        submitted: true,
        success: true,
      });

      mailObj = {};
    });

    // window.scroll(0, 0);
  }
  componentDidMount() {
    this.props.data.settings.form.map((item, i) => {
      return item.value.fieldtype === "label"
        ? this.setState({ withLabels: true })
        : null;
    });
  }

  render() {
    //console.log(this.props.url);
    const StyledTextField = styled(TextField)`
      label {
        color: ${this.props.textColor};
        opacity: 1;
      }
      label.focused {
        color: ${this.props.textColor};
        opacity: 1;
      }
      .MuiInputBase-input {
        color: ${this.props.textColor};
      }
      .MuiOutlinedInput-root {
        fieldset {
          border-color: ${this.props.textColor};
          opacity: 0.6;
          color: ${this.props.textColor};
        }
        &:hover fieldset {
          border-color: ${this.props.textColor};
          opacity: 1;
        }
        &.Mui-focused fieldset {
          color: ${this.props.textColor};
          opacity: 1;
        }
      }
    `;
    return (
      <div
        style={{
          backgroundColor: this.props.bgColor,
          color: this.props.textColor,
        }}
      >
        {this.state.withLabels ? (
          <div
            className="s-hide"
            style={{
              height: "1px",
              backgroundColor: this.props.textColor,
              margin: "0 auto 4rem auto",
              maxWidth: "640px",
            }}
          />
        ) : null}
        {!this.state.submitted ? (
          <div style={{ position: "relative" }}>
            <div
              className="sidebar s-hide"
              style={
                !this.state.withLabels
                  ? { position: "none" }
                  : {
                      position: "sticky",
                      top: "4rem",
                      padding: "2rem 2rem 2rem 4rem",
                      maxWidth: "360px",
                      float: "left",
                    }
              }
            >
              {this.props.data.settings.form.map((item, i) => {
                return item.value.fieldtype === "label" ? (
                  <div
                    key={i}
                    className={`formItem s-x100 x${item.value.width}`}
                  >
                    <a href={`#${item.value.id}`}>
                      <strong
                        style={{
                          color: this.props.textColor,
                          fontSize: "0.8rem",
                        }}
                        name={item.value.name}
                      >
                        {item.value.label}
                      </strong>
                    </a>
                  </div>
                ) : null;
              })}
            </div>
            {!this.state.loading ? (
              <form className="form" onSubmit={this.handleSubmit.bind(this)}>
                <h2
                  style={{
                    color: this.props.titleColor,
                  }}
                >
                  {this.props.data.settings.title}
                </h2>
                <div style={{ width: "100%" }}>
                  <ReactMarkdown
                    source={this.props.data.settings.description}
                    escapeHtml={false}
                  />
                </div>

                {this.props.data.settings.form.map((item, i) => {
                  return item.value.fieldtype === "text" ||
                    item.value.fieldtype === "" ? (
                    <div
                      key={i}
                      className={`formItem s-x100 x${item.value.width}`}
                    >
                      <StyledTextField
                        style={{
                          color: this.props.textColor,
                        }}
                        className="textfield text"
                        required={item.value.required}
                        id={item.value.id}
                        name={item.value.name}
                        label={item.value.label}
                        onChange={this.handleField(item.value.name)}
                        margin="normal"
                        variant="outlined"
                      />
                    </div>
                  ) : item.value.fieldtype === "number" ? (
                    <div
                      key={i}
                      className={`formItem s-x100 x${item.value.width}`}
                    >
                      <StyledTextField
                        style={{
                          color: this.props.textColor,
                        }}
                        className="textfield number"
                        type="number"
                        required={item.value.required}
                        id={item.value.id}
                        name={item.value.name}
                        label={item.value.label}
                        onChange={this.handleField(item.value.name)}
                        margin="normal"
                        variant="outlined"
                      />
                    </div>
                  ) : item.value.fieldtype === "file" ? (
                    <div
                      style={{
                        padding: "1rem",
                      }}
                      key={i}
                      className={`formItem s-x100 x${item.value.width}`}
                    >
                      <div
                        style={{
                          padding: "1rem",
                          border: `solid 1px ${this.props.textColor}`,
                          borderRadius: "4px",
                        }}
                      >
                        <label for={item.value.id}>
                          {item.value.label} {item.value.required ? "*" : null}
                        </label>
                        <br />
                        <input
                          id={item.value.id}
                          required={item.value.required}
                          type="file"
                          onChange={this.handleFile(item.value.name)}
                          name={item.value.name}
                        />
                      </div>
                    </div>
                  ) : item.value.fieldtype === "label" ? (
                    <div
                      key={i}
                      style={{ marginTop: "2rem" }}
                      className={`formItem s-x100 x${item.value.width}`}
                    >
                      <strong
                        style={{
                          color: this.props.textColor,
                          fontSize: "1rem",
                        }}
                        name={item.value.name}
                        id={item.value.id}
                      >
                        {item.value.label}
                      </strong>
                    </div>
                  ) : item.value.fieldtype === "sublabel" ? (
                    <div
                      key={i}
                      style={{ marginTop: "1rem" }}
                      className={`formItem s-x100 x${item.value.width}`}
                    >
                      <p
                        style={{
                          color: this.props.textColor,
                          fontSize: "0.8rem",
                          padding: "0 0 1rem 0",
                        }}
                        name={item.value.name}
                        id={item.value.id}
                      >
                        {item.value.label}
                      </p>
                    </div>
                  ) : item.value.fieldtype === "email" ? (
                    <div
                      key={i}
                      className={`formItem s-x100 x${item.value.width}`}
                    >
                      <StyledTextField
                        style={{
                          color: this.props.textColor,
                        }}
                        className="textfield email"
                        type="email"
                        required={item.value.required}
                        id={item.value.id}
                        name={item.value.name}
                        label={item.value.label}
                        onChange={this.handleField(item.value.name)}
                        margin="normal"
                        variant="outlined"
                      />
                    </div>
                  ) : item.value.fieldtype === "date" ? (
                    <div
                      key={i}
                      className={`formItem s-x100 x${item.value.width}`}
                    >
                      <StyledTextField
                        style={{
                          color: this.props.textColor,
                        }}
                        className="textfield date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        required={item.value.required}
                        id={item.value.id}
                        name={item.value.name}
                        label={item.value.label}
                        onChange={this.handleField(item.value.name)}
                        margin="normal"
                        variant="outlined"
                      />
                    </div>
                  ) : item.value.fieldtype === "textarea" ? (
                    <div
                      key={i}
                      className={`formItem s-x100 x${item.value.width}`}
                    >
                      <StyledTextField
                        style={{
                          color: this.props.textColor,
                        }}
                        className="textfield textarea"
                        required={item.value.required}
                        id={item.value.id}
                        name={item.value.name}
                        label={item.value.label}
                        onChange={this.handleField(item.value.name)}
                        margin="normal"
                        variant="outlined"
                        multiline
                      />
                    </div>
                  ) : item.value.fieldtype === "checkbox" ? (
                    <div
                      key={i}
                      className={`formItem s-x100 x${item.value.width}`}
                    >
                      <input
                        required={item.value.required}
                        onChange={this.handleChange(item.value.name)}
                        type="checkbox"
                        id={item.value.id}
                        name={item.value.name}
                        className="checkbox"
                      />
                      <label
                        style={{
                          paddingLeft: "0rem",
                        }}
                        htmlFor={item.value.id}
                      >
                        <ReactMarkdown
                          source={item.value.label}
                          escapeHtml={false}
                        />
                      </label>
                    </div>
                  ) : item.value.fieldtype === "select" ? (
                    <div
                      key={i}
                      className={`formItem s-x100 x${item.value.width}`}
                    >
                      <StyledTextField
                        style={{
                          color: this.props.textColor,
                        }}
                        className="textfield select"
                        select
                        required={item.value.required}
                        id={item.value.id}
                        name={item.value.name}
                        label={item.value.label}
                        onChange={this.handleField(item.value.name)}
                        margin="normal"
                        variant="outlined"
                        color="inherit"
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option disabled selected />
                        {item.value.options.split(",").map((option, j) => {
                          return (
                            <option style={{ color: "#333" }} key={option}>
                              {option}
                            </option>
                          );
                        })}
                      </StyledTextField>
                    </div>
                  ) : null;
                })}

                <div className="ctaContainer">
                  <Button
                    style={{
                      color: this.props.bgColor,
                      backgroundColor: this.props.textColor,
                    }}
                    variant="contained"
                    type="submit"
                  >
                    {this.props.data.settings.cta}
                  </Button>
                </div>
              </form>
            ) : null}
          </div>
        ) : this.state.success ? (
          <div className="form">
            <ReactMarkdown
              source={this.props.data.settings.thankyou}
              escapeHtml={false}
            />

            {this.props.data.settings.enable_mailchimp ? (
              <form
                className="form"
                style={{ display: "block", width: "100%" }}
                action={this.props.data.settings.mailchimp.action_url}
                method="post"
                name="mc-embedded-subscribe-form"
              >
                <div style={{ display: "none" }}>
                  {this.props.data.settings.form.map((item, i) => {
                    return item.value.in_mailchimp ? (
                      <input
                        id={item.value.id}
                        name={item.value.name}
                        value={mailObj[item.value.name]}
                      />
                    ) : null;
                  })}
                </div>
                <div style={{ width: "100%" }}>
                  <input
                    required={true}
                    type="checkbox"
                    id="mailchimp_checkbox"
                    className="checkbox"
                  />

                  <label
                    style={{
                      paddingLeft: "1rem",
                    }}
                    htmlFor="mailchimp_checkbox"
                  >
                    {this.props.data.settings.mailchimp.label}
                  </label>
                </div>

                <div style={{ display: "none" }}>
                  <input
                    type="text"
                    id="PAGE"
                    name="PAGE"
                    value={this.props.pageId}
                  />

                  <input
                    type="checkbox"
                    value="1"
                    name="group[19925][1]"
                    id="mce-group[19925]-19925-0"
                    checked={this.props.locale == undefined ? "true" : ""}
                  />
                  <label for="mce-group[19925]-19925-0">IT</label>
                  <input
                    type="checkbox"
                    value="2"
                    name="group[19925][2]"
                    id="mce-group[19925]-19925-1"
                    checked={this.props.locale === "de" ? "true" : ""}
                  />
                  <label for="mce-group[19925]-19925-1">DE</label>
                  <input
                    type="checkbox"
                    value="4"
                    name="group[19925][4]"
                    id="mce-group[19925]-19925-2"
                    checked={this.props.locale === "en" ? "true" : ""}
                  />
                  <label for="mce-group[19925]-19925-2">EN</label>
                </div>

                <br />
                <br />
                <Button
                  style={{
                    color: this.props.bgColor,
                    backgroundColor: this.props.textColor,
                  }}
                  variant="contained"
                  type="submit"
                >
                  {this.props.data.settings.mailchimp.cta}
                </Button>
              </form>
            ) : null}
          </div>
        ) : this.state.error ? (
          <div className="form">
            <h2 style={{ textAlign: "center" }}>Something went wrong :( </h2>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Form;
