import React, { PureComponent } from "react";

import { Link } from "react-router-dom";

import Fade from "react-reveal/Fade";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import LaunchIcon from "@material-ui/icons/Launch";
import Typography from "@material-ui/core/Typography";
import "../styles/cockpitComponents/careersFeed.css";

class CareersFeed extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: []
    };
  }

  async fetchData() {
    const response = await fetch(
      `https://seven.ch/admin/api/collections/get/careers`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "e9a764154266ec9a59b91348aa1966"
        },
        body: JSON.stringify({
          filter: { published: true },
          lang: this.props.locale,
          sort: { date: -1 }
          //limit: this.props.limit ? this.props.limit : 4
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    console.log(jsonData);
    this.setState({
      data: jsonData
    });
  }

  async componentDidMount() {
    window.scroll(0, 0);
    await this.setState({
      fetching: true
    });

    await this.fetchData();
    this.setState({
      fetching: false
    });
  }

  render() {
    return (
      <div className="careersFeed">
        <div
          className="careersFeedContainer"
          style={{
            backgroundColor: this.props.bgColor
          }}
        >
          <Fade bottom cascade>
            <div className="careersFeedContainerInner">
              {!this.state.fetching
                ? this.state.data.entries.map((career, i) => {
                    const splittedDate = career.date.split("-");

                    return (
                      <div
                        className="careerItem"
                        style={{ color: this.props.textColor }}
                      >
                        <h3 style={{ display: "inline-block" }}>
                          {career.title}
                        </h3>
                        <Button
                          style={{
                            color: this.props.textColor,
                            marginTop: "-1rem",
                            width: "2rem",
                            minWidth: "auto"
                          }}
                          size="small"
                          component={Link}
                          color="primary"
                          to={
                            !this.props.locale
                              ? `/careers/${career.url_handle}`
                              : `/${this.props.locale}/careers/${
                                  career.url_handle
                                }`
                          }
                        >
                          <LaunchIcon />
                        </Button>
                      </div>
                    );
                  })
                : null}
            </div>
          </Fade>
        </div>
      </div>
    );
  }
}

export default CareersFeed;
