import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import "../styles/cockpitComponents/tabs.css";
const ReactMarkdown = require("react-markdown/with-html");
function TabPanel(propsy) {
  const { children, value, index, ...other } = propsy;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
}));

export default function ScrollableTabsButtonAuto(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        style={{
          backgroundColor: props.bgColor,
          color: props.titleColor
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
        >
          {props.data.settings.tabs.map((tab, i) => {
            return <Tab label={tab.value.title} {...a11yProps(i)} />;
          })}
        </Tabs>
      </AppBar>

      {props.data.settings.tabs.map((tab, i) => {
        return (
          <TabPanel value={value} index={i}>
            {tab.value.content.map((item, j) => {
              return (
                <div className="thegrid tabcontent">
                  <div
                    className="thecell x100 padding  xs-x100"
                    style={{
                      textAlign: "justify",
                      paddingBottom: 0
                    }}
                  >
                    <ReactMarkdown
                      source={item.value.description}
                      escapeHtml={false}
                    />
                  </div>
                  {item.value.image ? (
                    <div className="thecell x50  padding xs-x100">
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src={`https://seven.ch/admin/api/cockpit/image?token=752611b463f91bb7434bacf241650d&src=${
                          item.value.image._id
                        }&w=1024&h=1024&q=60&o=true`}
                        alt={item.value.image.title}
                      />
                    </div>
                  ) : null}
                  <div className="thecell x50 padding  xs-x100">
                    <ReactMarkdown
                      source={item.value.content}
                      escapeHtml={false}
                    />
                    {item.value.cta && item.value.cta.url ? (
                      <Button
                        variant="contained"
                        style={{
                          color: props.textColor,
                          backgroundColor: props.bgColor,
                          minWidth: "160px",
                          padding: "1rem"
                        }}
                        href={item.value.cta.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.value.cta.label}
                      </Button>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </TabPanel>
        );
      })}
    </div>
  );
}
