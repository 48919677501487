import React, { PureComponent } from "react";
import Header from "./containers/Header";
import Footer from "./containers/Footer";

class Layout extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    //console.log(this.props.locale);
    return (
      <div className="layout">
        <Header
          locale={this.props.locale}
          url={this.props.url}
          params={this.props.params}
        />
        {this.props.children}
        <Footer locale={this.props.locale} url={this.props.url} />
      </div>
    );
  }
}

export default Layout;
