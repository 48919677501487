import React, { PureComponent } from "react";
import Fade from "react-reveal/Fade";

import "../styles/cockpitComponents/gridList.css";
import Swiper from "react-id-swiper";

class GridGallery extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      lightboxIsOpen: false,
      width: "100%",
      openIndex: 0
    };
  }

  toggleLightbox = index => {
    this.setState({
      lightboxIsOpen: true,
      openIndex: index
    });
    //  setTimeout(function() {

    document.body.style.overflow = "hidden";
    // setTimeout(function() {
    //   //  document.getElementById("lightBox").scrollTop = window.innerHeight * i;
    //   document.getElementById("lightBox").scroll({
    //     top: window.innerHeight * i,
    //     left: 0,
    //     behavior: "smooth"
    //   });
    // });

    //  }, 100);
  };

  closeLightbox = src => {
    this.setState({
      lightboxIsOpen: false
    });
    document.body.style.overflow = "";
  };

  render() {
    if (this.props.data.settings.width === "full") {
      this.setState({
        width: "100%"
      });
    }

    if (this.props.data.settings.width === "large") {
      this.setState({
        width: "1280px"
      });
    }
    if (this.props.data.settings.width === "medium") {
      this.setState({
        width: "1024px"
      });
    }
    if (this.props.data.settings.width === "small") {
      this.setState({
        width: "640px"
      });
    }

    const params = {
      pagination: {
        //el: ".swiper-pagination",
        el: null,
        type: "bullets",
        clickable: true
      },
      navigation: {
        nextEl: ".swiper-button-next.swiper-button-white",
        prevEl: ".swiper-button-prev.swiper-button-white"
      },
      spaceBetween: 0,

      //slidesPerView: 1,
      loop: false
    };

    return (
      <div
        className="gridListContainer"
        style={{
          backgroundColor: this.props.bgColor
        }}
      >
        <Fade bottom cascade>
          <div
            className="gridList"
            style={{
              maxWidth: this.state.width,
              margin: "0 auto"
            }}
          >
            {this.props.data.settings.gallery.map((item, i) => {
              return (
                <div
                  key={i}
                  className="gridListItem"
                  onClick={() => this.toggleLightbox(i)}
                >
                  <img
                    src={`https://seven.ch/admin/api/cockpit/image?token=752611b463f91bb7434bacf241650d&src=${
                      item.meta.asset
                    }&w=640&h=640&q=50&o=true`}
                    alt={item.meta.title}
                  />
                </div>
              );
            })}
          </div>
        </Fade>

        {this.state.lightboxIsOpen ? (
          <div
            className="lightBox"
            id="lightBox"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              height: "100vh",
              width: "100vw",
              zIndex: "10000",
              overflow: "auto",
              backgroundColor: "rgba(0,0,0,0.8)"
            }}
          >
            <Swiper
              {...params}
              activeSlideKey={this.state.openIndex.toString()}
            >
              {this.props.data.settings.gallery.map((item, i) => {
                return (
                  <div
                    style={{
                      padding: "1rem 0",
                      height: "100vh",
                      boxSizing: "border-box"
                    }}
                    key={i}
                  >
                    <div
                      style={{
                        height: "100%",
                        backgroundImage: `url(https://seven.ch/${item.path})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center"
                      }}
                    />
                  </div>
                );
              })}
            </Swiper>
            <div
              className="buttonCloseFull"
              onClick={() => this.closeLightbox()}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default GridGallery;
