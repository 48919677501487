import React, { PureComponent } from "react";

import "../styles/cockpitComponents/map.css";
import "leaflet/dist/leaflet.css";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";

const ReactMarkdown = require("react-markdown/with-html");

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

class MapComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lat: 0,
      lng: 0
    };
  }

  componentDidMount() {
    this.setState(this.props.data.settings.location);
    this.setState({
      ready: true
    });
  }

  render() {
    //console.log(this.props.data);
    const position = [this.state.lat, this.state.lng];

    return (
      <div
        className="mapContainer thegrid"
        style={
          this.props.data.settings.content_bg
            ? {
                backgroundColor: this.props.data.settings.content_bg
              }
            : {
                backgroundColor: this.props.textColor
              }
        }
      >
        <div
          className="mapContent thecell padding x33 s-x50 xs-x100"
          style={
            this.props.data.settings.content_color
              ? {
                  color: this.props.data.settings.content_color
                }
              : {
                  color: this.props.bgColor
                }
          }
        >
          <div>
            <ReactMarkdown
              source={this.props.data.settings.content}
              escapeHtml={false}
            />
          </div>
        </div>
        {this.state.ready ? (
          <Map
            className="thecell x66 s-x50 xs-x100"
            center={position}
            style={{ minHeight: "50vh" }}
            zoom={17}
            scrollWheelZoom={false}
            dragging={false}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}>
              <Popup>{this.props.data.settings.marker_text}</Popup>
            </Marker>
          </Map>
        ) : null}
      </div>
    );
  }
}

export default MapComponent;
