import React, { PureComponent } from "react";
import Fade from "react-reveal/Fade";

import "../styles/cockpitComponents/gridLogos.css";
import Swiper from "react-id-swiper";

class GridLogos extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      lightboxIsOpen: false,
      width: "100%",
      openIndex: 0
    };
  }

  render() {
    if (this.props.data.settings.width === "full") {
      this.setState({
        width: "100%"
      });
    }

    if (this.props.data.settings.width === "large") {
      this.setState({
        width: "1280px"
      });
    }
    if (this.props.data.settings.width === "medium") {
      this.setState({
        width: "1024px"
      });
    }
    if (this.props.data.settings.width === "small") {
      this.setState({
        width: "640px"
      });
    }

    const params = {
      pagination: {
        //el: ".swiper-pagination",
        el: null,
        type: "bullets",
        clickable: true
      },
      navigation: {
        nextEl: ".swiper-button-next.swiper-button-white",
        prevEl: ".swiper-button-prev.swiper-button-white"
      },
      spaceBetween: 0,

      //slidesPerView: 1,
      loop: false
    };

    return (
      <div
        className="gridLogosContainer"
        style={{
          backgroundColor: this.props.bgColor
        }}
      >
        <h2
          style={{
            color: this.props.titleColor
          }}
        >
          {this.props.data.settings.title}
        </h2>
        <Fade bottom cascade>
          <div
            className="gridLogos"
            style={{
              maxWidth: this.state.width,
              margin: "0 auto"
            }}
          >
            {this.props.data.settings.logos.map((item, i) => {
              return (
                <div key={i} className="gridLogosItem">
                  <img
                    src={`https://seven.ch/admin/api/cockpit/image?token=752611b463f91bb7434bacf241650d&src=${
                      item.meta.asset
                    }&w=640&h=640&q=50&o=true`}
                    alt={item.meta.title}
                  />
                </div>
              );
            })}
          </div>
        </Fade>
      </div>
    );
  }
}

export default GridLogos;
