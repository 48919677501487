import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import Layout from "../Layout";
import TagManager from "react-gtm-module";
import CockpitComponentsDispatcher from "../CockpitComponentsDispatcher";

class PagePreview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: []
    };
  }

  async componentDidMount() {
    var that = this;

    window.addEventListener(
      "message",
      function(event) {
        console.log("message received:  ", event);
        console.log("entry: ", event.data.entry);
        that.setState({
          data: event.data.entry,
          fetching: false
        });
      },
      false
    );
  }

  render() {
    console.log(this.state.fetching);
    return (
      <div className="page">
        {!this.state.fetching ? (
          <CockpitComponentsDispatcher
            pageId={null}
            data={this.state.data}
            locale={null}
          />
        ) : null}
      </div>
    );
  }
}

export default PagePreview;
